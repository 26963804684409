import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CheckIcon, ArrowLeftEndOnRectangleIcon } from "@heroicons/react/24/outline";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false); // State for "Remember Me"
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api.omom.vn/api/auth/login', { username, password, remember });
            if (remember) {
                localStorage.setItem('liveToken', response.data.liveToken);
                localStorage.setItem('username', response.data.username);
                localStorage.setItem('roleId', response.data.roleId);
                localStorage.setItem('fullName', response.data.fullName);
                localStorage.setItem('avatar', response.data.avatar);
                localStorage.setItem('userId', response.data.userId); // Store userId
            } else {
                sessionStorage.setItem('token', response.data.token);
                sessionStorage.setItem('username', response.data.username);
                sessionStorage.setItem('roleId', response.data.roleId);
                sessionStorage.setItem('fullName', response.data.fullName);
                sessionStorage.setItem('avatar', response.data.avatar);
                sessionStorage.setItem('userId', response.data.userId); // Store userId
            }
            navigate('/');
            window.location.reload();
        } catch (err) {
            const errorMessage = err.response.data.message;
            setError(errorMessage);
        }
    };

    return (
        <div className="min-h-screen flex items-start justify-center pt-6 p-3">
            <div className="p-8 w-full max-w-md">
                <h2 className="text-2xl font-medium mb-6 text-center">Đăng nhập vào Nhựa Đại Việt</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <input
                            placeholder="Tên đăng nhập"
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            className="w-full p-2 border border-gray-300 rounded mt-1"
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            placeholder="Mật khẩu"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="w-full p-2 border border-gray-300 rounded mt-1"
                        />
                    </div>
                    <div className="inline-flex items-center mb-4 py-3">
                        <label className="relative flex items-centerrounded-full cursor-pointer"
                               htmlFor="customStyle">
                            <input type="checkbox"
                                   checked={remember}
                                   onChange={(e) => setRemember(e.target.checked)}
                                   className="before:content[''] peer relative h-8 w-8 cursor-pointer appearance-none rounded-full border border-blue-500/20 bg-blue-500/10 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-blue-500 hover:scale-105 hover:before:opacity-0"
                                   id="customStyle"/>
                            <span
                                className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                            <CheckIcon className="h-4 w-4 cursor-pointer" />
                            </span>
                        </label>
                        <p className="ml-3">Duy trì đăng nhập</p>
                    </div>
                    {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
                    <button type="submit"
                            className="w-full flex justify-center bg-gradient-to-r from-blue-400 to-blue-600 text-white p-2 rounded">
                        <ArrowLeftEndOnRectangleIcon className="h-6 w-6 cursor-pointer mr-3" />
                        Đăng nhập
                    </button>
                </form>
                <div className="mt-4 flex justify-around">
                    <button
                        onClick={() => navigate('/register')}
                        className="w-full bg-blue-50 text-blue-600 border-2 border-blue-300 p-2 rounded hover:bg-blue-200 transition duration-200 mr-1"
                    >
                        Đăng ký
                    </button>
                    <button
                        onClick={() => navigate('/')}
                        className="w-full bg-blue-50 text-blue-600 border-2 border-blue-300 p-2 rounded hover:bg-blue-200 transition duration-200 mr-1"
                    >
                        Trở về trang chủ
                    </button>
                </div>
                <p className="pt-2">Quên mật khẩu? <a onClick={() => navigate('/forgot-password')} className="font-medium text-blue-500">
                    Đặt lại mật khẩu
                </a>
                </p>
            </div>
        </div>
    );
};

export default Login;
