import React, { useState } from 'react';
import imageCompression from 'browser-image-compression';
import {ArrowUpTrayIcon} from "@heroicons/react/24/outline";

const ImageUpload = ({ onImageUpload, existingImages }) => {
    const [selectedImages, setSelectedImages] = useState([]);
    const [showExistingImages, setShowExistingImages] = useState(true);

    const convertToJPG = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    canvas.toBlob((blob) => {
                        resolve(new File([blob], file.name.replace(/\.\w+$/, '.jpg'), { type: 'image/jpeg' }));
                    }, 'image/jpeg', 0.8);  // Adjust quality as needed
                };
                img.src = event.target.result;
            };
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    };

    const handleFileChange = async (e) => {
        setShowExistingImages(false);
        const files = Array.from(e.target.files);
        const compressedFiles = await Promise.all(files.map(async (file) => {
            const options = {
                maxSizeMB: 4,
                maxWidthOrHeight: 1440,
                useWebWorker: true
            };
            try {
                const compressedFile = await imageCompression(file, options);
                const jpgFile = await convertToJPG(compressedFile);
                return {
                    preview: URL.createObjectURL(jpgFile),
                    name: jpgFile.name,
                    file: jpgFile
                };
            } catch (error) {
                console.error('Error during image compression', error);
                return {
                    preview: URL.createObjectURL(file),
                    name: file.name,
                    file
                };
            }
        }));
        setSelectedImages(compressedFiles);
        onImageUpload(compressedFiles.map(img => img.file));
    };

    return (
        <div>
            <div className="flex items-center justify-center w-full">
                <label htmlFor="dropzone-file"
                       className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                    <div className="flex flex-col items-center justify-center pt-8 pb-3">

                        <ArrowUpTrayIcon className="w-12 h-12 mb-4 text-gray-500 dark:text-gray-400"/>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span
                            className="font-semibold">Nhấn</span> hoặc kéo ảnh vào đây</p>
                        <p className="text-xs text-gray-500 dark:text-gray-400 text-center">Chỉ hỗ trợ định dạng PNG hặc
                            JPG (kích thước tối đa 2000x2000px)</p>
                    </div>
                    <input multiple id="dropzone-file" type="file"
                           className="cursor-pointer relative block opacity-0 w-full h-full p-20 z-50 -mt-36"
                           onChange={handleFileChange}/>
                </label>
            </div>
            {showExistingImages && existingImages && existingImages.length > 0 && (
                <div className="mt-4 grid grid-cols-2 gap-4">
                    {existingImages.map((image, index) => (
                        <div key={index} className="relative">
                            <img
                                src={image.preview}
                                alt={`Existing image ${index + 1}`}
                                className="object-cover w-full h-32 rounded-md"
                            />
                        </div>
                    ))}
                </div>
            )}
            {selectedImages.length > 0 && (
                <div className="mt-4 grid grid-cols-2 gap-4">
                    {selectedImages.map((image, index) => (
                        <div key={index} className="relative">
                            <img
                                src={image.preview}
                                alt={image.name}
                                className="object-cover w-full h-32 rounded-md"
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ImageUpload;
