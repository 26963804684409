import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email.trim()) {
            setError('Vui lòng nhập email của bạn');
            return;
        }
        if (!recaptchaToken) {
            setError('Vui lòng xác nhận bạn không phải là robot');
            return;
        }
        try {
            await axios.post('https://api.omom.vn/api/auth/forgot-password', { email });
            toast.success('Yêu cầu đặt lại mật khẩu đã được gửi. Vui lòng kiểm tra email của bạn.');
            setTimeout(() => {
                navigate('/');
            }, 3000);
        } catch (error) {
            setError('Không thể gửi yêu cầu đặt lại mật khẩu');
        }
    };

    return (
        <div className="min-h-screen flex items-start justify-center pt-2">
            <ToastContainer />
            <div className="bg-white p-6 rounded-lg w-full max-w-md">
                <h2 className="text-2xl font-bold mb-6">Quên mật khẩu</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Nhập email của bạn*</label>
                        <input
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            className="border rounded w-full py-2 px-3 text-gray-700"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <ReCAPTCHA
                            sitekey="6Ld8VwIqAAAAAE1SGyC0BQbLFddn3zcNlfX-21ie"
                            onChange={handleRecaptchaChange}
                        />
                    </div>
                    {error && <p className="text-red-500 text-xs italic">{error}</p>}
                    <div className="flex items-center justify-between">
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Gửi yêu cầu
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
